<!-- 我的消息 -->
<template>
  <div class="my_message">
    <Spin size="large" fix v-if="spinShow"></Spin>
    <ul v-if="message_list.length">
      <li v-for="(item,index) in message_list" :key="item._id">
        <div class="top">

          <Badge status="error" v-if="item.status == 1" />
          <p style="font-size: 16px;">系统通知</p>
          <p style="margin-left:20px">{{item.date | dayjs}}</p>
        </div>

        <div class="bottom">
          <p>{{item.details}}</p>
          <img src="@/assets/images/message_detail.png" style="margin-left:20px;cursor: pointer;" alt="">
          <span style="color:#165DFF;cursor: pointer;" @click="check(item)">前去查看</span>

        </div>

      </li>
    </ul>

    <div class="no_data" v-else>
      <img src="@/assets/images/noMessage.png" alt="">
      <p>暂无消息</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message_list: [],
      spinShow: true,
    }
  },
  methods: {
    check(item) {
      console.log(item);
      //取消红点变更为已读状态

      this.app("enterpriseOperators", "updateInformationStatus", {
        _id: item._id,
        status: 2
      }).then(res => {
        console.log(res);
      })



      if (item.type == 1) {
        this.$router.push("firm_data")
        this.$emit("setTitle", "企业资料")
      } else {
        this.$router.push("job_manage")
        this.$emit("setTitle", "职位管理")
      }
    },
    //封装获取数据
    get_data() {
      this.spinShow = true
      this.app('enterpriseOperators', 'getInformationList', {
        enterpriseId: localStorage.getItem('firm_enterpriseId'),
        page: 1,
        size: 100
      }).then(res => {
        console.log(res);
        this.message_list = res.result.result
        this.spinShow = false
      })
    }
  },
  created() {
    this.get_data()
  }
}
</script>

<style lang="less" scoped>
.my_message {
  position: relative;
  ul {
    li {
      padding: 30px;
      background: #fff;
      margin-bottom: 20px;

      .top {
        display: flex;
        margin-bottom: 10px;
        align-items: center;
      }
      .bottom {
        display: flex;
        align-items: center;
      }
    }
  }
  .no_data {
    text-align: center;
    margin-top: 100px;
  }
}
</style>
